import axiosClient from './axiosClient';

const prefectureApi = {
    getPrefecture(params) {
        const url = '/prefecture';
        return axiosClient.get(url, { params });
    },
};

export default prefectureApi;
