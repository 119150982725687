import { Button, Divider, Form, Modal, Select } from 'antd';
import { useEffect, useState, useTransition } from 'react';
import styles from './styles.module.scss';
import DatePickerCell from './DatePickerCell';
import storeApi from '~/api/store.api';
import siteApi from '~/api/site.api';
import { showNotification } from '~/utils';

function AddJob({ isModalStoreShow, onClose }) {
  const [stores, setStores] = useState([]);
  const [storeId, setStoreId] = useState(null);
  const [recrawlDate, setRecrawlDate] = useState(null);
  const [sites, setSites] = useState([]);
  const [currentSite, setCurrentSite] = useState(0);
  const [isClicked, setClicked] = useState(false);
  const [error, setError] = useState({});
  const [pending, startTransition] = useTransition();
  

  async function fetchSites() {
    try {
      const sites = (await siteApi.getAllSites()).data;
      setSites(sites)
    } catch (error) {
    }
  }

  async function fetchStores() {
    const getStoreParams = {
      siteId: currentSite,
    };

    try {
      const storeData = (await storeApi.getAllStores(getStoreParams));
      const stores = storeData.data;
      setStores(stores);
    } catch (error) {
    }
  }

  useEffect(() => {
    fetchSites();
  }, []);

  useEffect(() => {
    fetchStores();
  }, [currentSite]);

  useEffect(() => {
    setStores([])
    setCurrentSite(0)
    setStoreId(null)
    setRecrawlDate(null)
  }, [isModalStoreShow])

  const onSearch = (value) => {
    startTransition(async () => {
      const getStoreParams = {
        keyword: value,
        siteId: currentSite,
      };

      const storeData = (await storeApi.getAllStores(getStoreParams));
      const stores = storeData.data;
      setStores(stores);
    });
  }

  const submitForm = async () => {
    setClicked(true)
    if (!storeId) {
      error.store = true;
    }

    if (!recrawlDate) {
      error.recrawlDate = true;
    }
    try {
      if (!storeId || !recrawlDate) {
        // TODO: change error message
        throw new Error('Please choose store or recrawl date!');
      }

      await storeApi.createRecrawl({
        storeId, recrawlDate: recrawlDate.format('YYYY-MM-DD')
      });
    
      onClose();
      
      // TODO: change success message
      showNotification('success', '', 'OK')
    } catch (error) {
      setError({ ...error })
      showNotification('error', '', error.message)
    } finally {
        setClicked(false)
    }
  }

  return (
    <div>
      <Modal
        title="店舗追加"
        centered
        visible={true}
        width={'40vw'}
        footer={null}
        onCancel={onClose}
        maskClosable={false}
        className={styles.wrapper}
        destroyOnClose={true}
      >
        <Form
          layout="vertical"
          onFinish={onClose}
        >
          <Form.Item
            label="サイト"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              options={sites.map(s => ({ label: `${s.name}`, value: s.id }))}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onSearch={onSearch}
              onChange={(e) => {
                setCurrentSite(e);
                setStoreId(null)
              }}
            />
          </Form.Item>
          <Form.Item
            label="店舗コード"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              allowClear
              options={stores.map(s => ({ label: `${s.code} - ${s.name}`, value: s.id }))}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onSearch={onSearch}
              onChange={(value) => {
                setError({ ...error, store: false });
                setStoreId(value);
              }}
              value={storeId}
              status={error.store && "error"}
            />
          </Form.Item>
          <Form.Item
            label="日付"
            rules={[{ required: true }]}
          >
            <DatePickerCell
              siteId={currentSite}
              status={error.recrawlDate && "error"} 
              onChange={(date) => {
                setError({ ...error, recrawlDate: false });
                setRecrawlDate(date)
              }}
            />
          </Form.Item>
          <Divider />
          <Form.Item>
            <div className={styles.action}>
              <Button onClick={onClose}>
                キャンセル
              </Button>
              <Button disabled={isClicked} type="primary" onClick={submitForm}>
                追加
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default AddJob;
