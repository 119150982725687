import { DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, notification, Row, Select } from 'antd';
import { isArray, last } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import cookieApi from '~/api/cookie.api';
import downloadApi from '~/api/download.api';
import graphApi from '~/api/graph.api';
import historyApi from '~/api/history.api';
import modelApi from '~/api/model.api';
import siteApi from '~/api/site.api';
import storeApi from '~/api/store.api';
import typeApi from '~/api/type.api';
import { ActiveStatus, ReactQueryKey, ProgressStatus } from '~/common';
import AddStore from '~/components/modals/AddStore';
import Priority from '~/components/modals/Priority';
import ModalStore from '~/components/modals/Store';
import StoreRecaptcha from '~/components/tables/StoreRecaptcha';
import { handleErrorMessage } from '~/helpers';
import styles from './styles.module.scss';

const { Option } = Select;

function PscubeRecaptcha() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const [params, setParams] = useState({
        current: 1,
        pageSize: 10,
        total: 1,
        keyword: '',
        status: '',
    });
    const [priority, setPriority] = useState({
        priority: 1,
        stores: [],
    });

    // detail store state
    const [selectedStore, setSelectedStore] = useState(null);
    const [types, setTypes] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [models, setModels] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [units, setUnits] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [graphs, setGraphs] = useState(null);

    const [isStoreDetailModelShow, setIsStoreDetailModelShow] = useState(false);
    const [isGraphsModelShow, setIsGraphsModelShow] = useState(false);
    const [isModalStoreShow, setModalStoreShow] = useState(false);
    const [isModalPriorityShow, setModalPriorityShow] = useState(false);

    const { data: sites } = useQuery(ReactQueryKey.GetSites, () => siteApi.getSites());

    const { data: stores, isLoading: isStoresLoading } = useQuery(
        [ReactQueryKey.GetStoreRecaptcha, params],
        () =>
            storeApi.getStoresPscubeRecaptcha({
                pageIndex: params.current,
                take: params.pageSize,
                keyword: params.keyword,
                status: params.status,
            }),
        {
            onSuccess: (response) => {
                setParams((prev) => ({
                    ...prev,
                    current: response?.pageIndex,
                    total: response?.totalItems,
                }));
            },
            onError: (error) => {
                handleErrorMessage(error);
            },
        }
    );

    const { mutate } = useMutation((params) => cookieApi.createReCrawl(params), {
        onSuccess: () => {
            notification.success({
                message: '動作成功',
                description: 'クローリング処理を開始しました。',
            });
            queryClient.invalidateQueries(ReactQueryKey.GetStoreRecaptcha);
        },
        onError: (error) => {
            handleErrorMessage(error);
        },
    });

    useQuery(
        [ReactQueryKey.GetTypes, selectedStore],
        () =>
            typeApi.getTypesByStoreId({
                storeId: selectedStore?.id,
                date: moment().format('YYYY-MM-DD'),
            }),
        {
            onSuccess: (response) => {
                setTypes(response?.data);
                setSelectedType(response?.data?.length > 0 && response?.data[0]);
            },
            onError: (error) => {
                handleErrorMessage(error);
            },
            enabled: !!selectedStore,
            cacheTime: 0,
            keepPreviousData: false,
        }
    );

    const { isLoading: isModelsLoading } = useQuery(
        [ReactQueryKey.GetModels, selectedType],
        () =>
            modelApi.getModelsByStoreId({
                storeId: selectedStore?.id,
                typeId: selectedType?.id,
                date: moment().format('YYYY-MM-DD'),
            }),
        {
            onSuccess: (response) => {
                setModels(response.data);
            },
            onError: (error) => {
                handleErrorMessage(error);
            },
            enabled: !!selectedType,
            cacheTime: 0,
            keepPreviousData: false,
        }
    );

    const { isLoading: isUnitsLoading } = useQuery(
        [ReactQueryKey.GetHistories, selectedModel],
        () =>
            historyApi.getHistoriesByStoreId({
                storeId: selectedStore?.id,
                typeId: selectedType?.id,
                modelId: selectedModel?.id,
                date: moment().format('YYYY-MM-DD'),
            }),
        {
            onSuccess: (response) => {
                setUnits(response.data);
            },
            onError: (error) => {
                handleErrorMessage(error);
            },
            enabled: !!selectedModel,
            cacheTime: 0,
            keepPreviousData: false,
        }
    );

    const { isLoading: isGraphsLoading } = useQuery(
        [ReactQueryKey.GetGraphs, selectedUnit],
        () =>
            graphApi.getGraphs({
                storeId: selectedStore?.id,
                historyId: selectedUnit?.id,
            }),
        {
            onSuccess: (response) => {
                setGraphs(response.data);
            },
            onError: (error) => {
                handleErrorMessage(error);
            },
            enabled: !!selectedUnit,
            cacheTime: 0,
            keepPreviousData: false,
        }
    );

    const onFinish = (values) => {
        setParams((prev) => ({
            ...prev,
            keyword: values?.keyword ? values?.keyword : '',
            status: values?.status || values?.status === 0 ? values?.status : '',
            current: 1,
        }));
    };

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setParams((prev) => ({
            ...prev,
            current: pagination?.current,
            pageSize: pagination?.pageSize,
        }));
    };

    const onGenderChange = (value) => {
        switch (value) {
            case '':
                form.setFieldsValue({ status: '' });
                return;
            case ProgressStatus.Waiting:
                form.setFieldsValue({ status: ProgressStatus.Waiting });
                return;
            case ProgressStatus.InProgress:
                form.setFieldsValue({ status: ProgressStatus.InProgress });
                return;
            case ProgressStatus.Success:
                form.setFieldsValue({ status: ProgressStatus.Success });
                return;
            default:
                return;
        }
    };

    const handleReCrawl = (params) => {
        mutate({
            ...params,
        });
    };

    //  detail store handle
    const handleSelectStore = (record) => {
        setSelectedStore(record);
        setIsStoreDetailModelShow(true);
    };

    const handleStoreDetailModelClose = () => {
        setIsStoreDetailModelShow(false);
        setModels(null);
        setSelectedModel(null);
        setUnits(null);
        setSelectedUnit(null);
        setTypes(null);
        setSelectedType(null);
        setSelectedStore(null);
    };

    const handleSelectType = (type) => {
        setSelectedType(type);
        setSelectedModel(null);
    };

    const handleSelectModel = (key) => {
        const id = last(key);
        const model = models?.find((model) => model?.id === Number(id));
        setSelectedModel(model);
    };

    const handleSelectUnit = (unit) => {
        setSelectedUnit(unit);
        setIsGraphsModelShow(true);
    };

    const handleGraphModelClose = () => {
        setIsGraphsModelShow(false);
    };

    const handleModalStore = () => {
        setModalStoreShow(true);
    };

    const handleCloseModal = () => {
        setModalStoreShow(false);
    };

    const handleDownloadCsv = async () => {
        try {
            const res = await downloadApi.csv({
                start: moment().format('YYYY/MM/DD'),
                end: moment().format('YYYY/MM/DD'),
                storeId: selectedStore?.id,
            });
            let fileName = res.headers['content-disposition'].split('filename=')[1];
            fileName = decodeURI(fileName);

            const listUint8 = new Uint8Array(res.data);
            const blob = new Blob([listUint8], {
                type: 'text/csv',
            });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleAddPriority = async () => {
        setPriority((prev) => ({
            ...prev,
            stores: selectedRowKeys,
            priority: ActiveStatus.Active,
        }));
        setModalPriorityShow(true);
    };

    const handleDeletePriority = async () => {
        setPriority((prev) => ({
            ...prev,
            stores: selectedRowKeys,
            priority: ActiveStatus.InActive,
        }));
        setModalPriorityShow(true);
    };

    const handleClosePriorityModal = () => {
        setModalPriorityShow(false);
    };

    const handleSelectedRowsKey = () => {
        setSelectedRowKeys([]);
    };

    return (
        <>
            <h2>店舗管理</h2>

            <div className={styles.formWrapper}>
                <Form
                    className={styles.form}
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="keyword" rules={[]}>
                                <Input placeholder="店舗名、住所、県で検索できます" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="status" rules={[]}>
                                <Select placeholder="状態" onChange={onGenderChange} allowClear>
                                    <Option value="">全て</Option>
                                    <Option value={ProgressStatus.Waiting}>
                                        クローリング待機
                                    </Option>
                                    <Option value={ProgressStatus.InProgress}>
                                        クローリング中
                                    </Option>
                                    <Option value={ProgressStatus.Success}>
                                        クローリング済み
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item>
                                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
                                    検索
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Button
                    className={styles.action}
                    onClick={handleModalStore}
                    type="primary"
                    icon={<PlusOutlined />}
                    htmlType="submit"
                >
                    店舗追加
                </Button>
            </div>
            <div className={styles.priority}>
                <Button
                    disabled={selectedRowKeys?.length > 0 ? false : true}
                    className={styles.action}
                    onClick={handleAddPriority}
                    type="primary"
                    icon={<PlusOutlined />}
                    htmlType="submit"
                >
                    優先度一覧に追加
                </Button>
                <Button
                    disabled={selectedRowKeys?.length > 0 ? false : true}
                    className={styles.action}
                    onClick={handleDeletePriority}
                    danger
                    icon={<DeleteOutlined />}
                    htmlType="submit"
                >
                    優先度一覧から削除
                </Button>
            </div>

            <StoreRecaptcha
                stores={stores}
                isStoresLoading={isStoresLoading}
                onTableChange={handleTableChange}
                pagination={params}
                onReCrawl={handleReCrawl}
                onSelectStore={handleSelectStore}
                selected={selectedRowKeys}
                onSelectedRowKeys={(item) => setSelectedRowKeys(item)}
            />

            <AddStore sites={sites} isModalStoreShow={isModalStoreShow} onClose={handleCloseModal} />

            {isArray(types) && (
                <ModalStore
                    selectedStore={selectedStore}
                    isStoreDetailModelShow={isStoreDetailModelShow}
                    onStoreDetailModelClose={handleStoreDetailModelClose}
                    types={types}
                    selectedType={selectedType}
                    onSelectType={handleSelectType}
                    models={models}
                    isModelsLoading={isModelsLoading}
                    selectedModel={selectedModel}
                    onSelectModel={handleSelectModel}
                    units={units}
                    isUnitsLoading={isUnitsLoading}
                    onSelectUnit={handleSelectUnit}
                    isGraphsModelShow={isGraphsModelShow}
                    onGraphModelClose={handleGraphModelClose}
                    graphs={graphs}
                    isGraphsLoading={isGraphsLoading}
                    onDownloadCsv={handleDownloadCsv}
                />
            )}

            <Priority
                isPriorityModalShow={isModalPriorityShow}
                onClose={handleClosePriorityModal}
                isPriority={priority}
                onSelectedRowsKey={handleSelectedRowsKey}
            />
        </>
    );
}

export default PscubeRecaptcha;
