import axiosClient from './axiosClient';

const storeApi = {
    getStoresPscubeRecaptcha(params) {
        const url = '/stores';
        return axiosClient.get(url, { params });
    },

    createStoresPscubeRecaptcha(params) {
        const url = '/stores';
        return axiosClient.post(url, params);
    },

    setPriorityPscubeRecaptcha(params) {
        const url = '/stores/priority';
        return axiosClient.post(url, params);
    },

    getStoresCookies(params) {
        const url = '/stores/pscube-cookies';
        return axiosClient.get(url, { params });
    },

    getAllStores(params) {
        const url = '/stores/all';
        return axiosClient.get(url, { params });
    },

    getAllRecrawl(params) {
        const url = '/recrawl';
        return axiosClient.get(url, { params });
    },

    createRecrawl(params) {
      const url = '/recrawl';
      return axiosClient.post(url, params);
  },
};

export default storeApi;
