import { Modal, Table } from 'antd';

function ModalGraph({ isGraphsModelShow, onGraphModelClose, graphs, isGraphsLoading }) {
    const columns = [
        {
            title: 'No.',
            render: (text, record, index) => <>{index + 1}</>,
        },
        {
            title: 'アウト',
            dataIndex: 'x',
        },
        {
            title: '差玉',
            dataIndex: 'y',
        },
    ];
    return (
        <>
            <Modal
                title={'200点の情報'}
                centered
                visible={isGraphsModelShow}
                onCancel={() => onGraphModelClose()}
                width={'500px'}
                footer={null}
                maskClosable={false}
                bodyStyle={{ overflowY: 'scroll', height: 'calc(100vh - 100px)' }}
            >
                <Table
                    rowKey={(item) => item.id}
                    loading={isGraphsLoading}
                    columns={columns}
                    dataSource={graphs}
                    pagination={false}
                />
            </Modal>
        </>
    );
}

export default ModalGraph;
