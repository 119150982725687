import axiosClient from './axiosClient';

const modelApi = {
    getModelsByStoreId(params) {
        const url = '/models';
        return axiosClient.get(url, { params });
    },
};

export default modelApi;
