import Cookies from 'js-cookie';
import { useLocation, Navigate } from 'react-router-dom';

function PrivateWrapper({ children }) {
    const location = useLocation();
    const token = Cookies.get('token');

    if (!token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default PrivateWrapper;
