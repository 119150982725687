export const ProgressStatus = {
  Waiting: 0,
  InProgress: 1,
  Success: 2,
  Failed: 3,
};

export const ReactQueryKey = {
  GetStoreRecaptcha: 'GetStoreRecaptcha',
  GetStoreCookies: 'GetStoreCookies',
  GetDomains: 'GetDomains',
  GetListDomain: 'GetListDomain',
  GetTypes: 'GetTypes',
  GetModels: 'GetModels',
  GetHistories: 'GetHistories',
  GetGraphs: 'GetGraphs',
  GetPrefecture: 'GetPrefecture',
  GetSites: 'GetSites',
};

export const DynamData = [
  'a725536',
  'a725427',
  'a725249',
  'a725578',
  'a725300',
  'a725339',
  'a725371',
  'a725540',
  'a725422',
  'a725570',
];

export const ActiveStatus = {
  InActive: 0,
  Active: 1,
};

export const CrawlStatus = [
  {
    name: 'クローリング待機',
    value: ProgressStatus.Waiting,
    color: 'gold',
  },
  {
    name: 'クローリング中',
    value: ProgressStatus.InProgress,
    color: 'blue',
  },
  {
    name: 'クローリング済み',
    value: ProgressStatus.Success,
    color: 'success',
  },
  {
    name: 'クローリング失敗',
    value: ProgressStatus.Failed,
    color: 'red',
  }
];

export const PAGE_SIZE = 10;

export const commonStatus = {
  InActive: 0,
  Active: 1,
};

export const StoreStatus = {
  Normal: 0,
  Recaptcha: 4,
};

export const SiteId = {
  PsCube: 3,
  Teramoba2: 16,
};
