import axiosClient from './axiosClient';

const siteApi = {
  getSites() {
    const url = '/sites';
    return axiosClient.get(url);
  },
  getAllSites() {
    const url = '/sites/all';
    return axiosClient.get(url);
  }
};

export default siteApi;
