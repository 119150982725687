import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tag } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DynamData } from '~/common';
import TagReCrawl from '~/components/TagReCrawl';
import styles from './styles.module.scss';

function TableStoreRecaptcha({
    stores,
    isStoresLoading,
    onTableChange,
    pagination,
    onReCrawl,
    onSelectStore,
    selected,
    onSelectedRowKeys,
}) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        onSelectedRowKeys(newSelectedRowKeys);
    };
    useEffect(() => {
        if (selected?.length === 0) {
            setSelectedRowKeys([]);
        }
    }, [selected]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    function assignPscubeDomain(record) {
        if (record?.SitePsCubeDomain?.url) {
            return (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${record?.SitePsCubeDomain?.url}/${record.code}`}
                >
                    URL
                </a>
            );
        } else if (DynamData.includes(record?.code)) {
            return (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://www.dynam-data.jp/h/${record.code}`}
                >
                    URL
                </a>
            );
        } else if (!DynamData.includes(record?.code) && record?.code[0] === 'a') {
            return (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://www.pscube.jp/h/${record.code}`}
                >
                    URL
                </a>
            );
        } else if (!DynamData.includes(record?.code) && record?.code[0] !== 'a') {
            return (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://www.pscube.jp/dedamajyoho-P-townDMMpachi/${record.code}`}
                >
                    URL
                </a>
            );
        }
    }

    const columns = [
        {
            title: 'サイト',
            render: (text, record, index) => <span>{record.site}</span>,
        },
        {
            title: '店舗コード',
            dataIndex: 'code',
        },
        {
            title: '店舗名',
            dataIndex: 'name',
        },
        {
            title: '県',
            dataIndex: 'prefecture',
        },
        {
            title: '住所',
            dataIndex: 'address',
        },
        {
            title: '状態',
            render: (text, record, index) => (
                <>
                    {record.status === 4 && record.lastCrawl !== moment().format('YYYY-MM-DD') && (
                        <Tag color="red">クローリング待機</Tag>
                    )}

                    {record.status === 5 && <Tag color="blue">クローリング中</Tag>}

                    {record.status === 4 && record.lastCrawl === moment().format('YYYY-MM-DD') && (
                        <Tag>クローリング済み</Tag>
                    )}
                </>
            ),
        },
        {
            title: '最新クローリング時間',
            render: (text, record, index) => <>{moment(record.lastCrawl).format('YYYY/MM/DD')}</>,
        },
        {
            title: '補足クロール',
            render: (text, record, index) => (
                <Space direction="vertical">
                    <TagReCrawl record={record} subtract={0} onReCrawl={onReCrawl} />
                    <TagReCrawl record={record} subtract={2} onReCrawl={onReCrawl} />
                    <TagReCrawl record={record} subtract={3} onReCrawl={onReCrawl} />
                </Space>
            ),
        },
        {
            title: 'Cookies取得状況',
            render: (text, record, index) => (
                <>
                    {record?.cookies && (
                        <Button
                            size="small"
                            type="primary"
                            shape="circle"
                            icon={<CheckOutlined />}
                        />
                    )}

                    {!record?.cookies && <div>-</div>}
                </>
            ),
        },
        {
            title: '店舗URL',
            render: (text, record, index) => <>{assignPscubeDomain(record)}</>,
        },
        {
            title: '当日CSV',
            render: (text, record, index) => (
                <>
                    <Button
                        shape="circle"
                        icon={<EyeOutlined />}
                        onClick={() => onSelectStore(record)}
                    />
                </>
            ),
        },
    ];

    return (
        <Table
            rowSelection={rowSelection}
            rowKey={(item) => item.id}
            pagination={pagination}
            loading={isStoresLoading}
            onChange={onTableChange}
            columns={columns}
            dataSource={stores?.data}
            rowClassName={(record) => classNames({ [styles.priority]: !!record?.priority })}
        />
    );
}

export default TableStoreRecaptcha;
