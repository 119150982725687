import { Table, Tag } from 'antd';
import React from 'react';
import { CrawlStatus } from '~/common';

function StoreTable({ data, pagination, handleTableChange, loading }) {
  data = data.map(c => ({
    ...c,
    code: c.Store.code,
    name: c.Store.name,
    address: c.Store.address,
    site: c.Store.Site.name,
  }));

  const columns = [
    {
      title: 'サイト',
      dataIndex: 'site'
    },
    {
      title: '日付',
      dataIndex: 'recrawlDate'
    },
    {
      title: '店舗コード',
      dataIndex: 'code'
    },
    {
      title: '店舗名',
      dataIndex: 'name'
    },
    {
      title: '状況',
      render: (text, record, index) => {
        const status = CrawlStatus.find(s => s.value === record.status)
        return (
          <Tag color={status.color}>{status.name}</Tag>
        )
      },
    },
    {
      title: '住所',
      dataIndex: 'address'
    },

  ];

  /**
   * Handle recrawl by row and picked date
   * @param {*} index 
   */

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={pagination}
      rowKey={(item) => item.id}
    />
  );
}

export default StoreTable;