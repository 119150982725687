import axiosClient from './axiosClient';

const typeApi = {
    getTypesByStoreId(params) {
        const url = '/types';
        return axiosClient.get(url, { params });
    },
};

export default typeApi;
