import axiosClient from './axiosClient';

const historyApi = {
    getHistoriesByStoreId(params) {
        const url = '/histories';
        return axiosClient.get(url, { params });
    },
};

export default historyApi;
