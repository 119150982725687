import { Button, Divider, Modal, notification } from 'antd';
import { useQueryClient, useMutation } from 'react-query';
import domainApi from '~/api/domain.api';
import { ReactQueryKey } from '~/common';
import { handleErrorMessage } from '~/helpers';
import styles from './styles.module.scss';

function DeleteDomain({ isModalDeleteShow, listDelete, onClose }) {
    const queryClient = useQueryClient();

    const { mutate } = useMutation((param) => domainApi.deleteDomains(param), {
        onSuccess: (response) => {
            for (let item of response?.data) {
                if (item.status) {
                    notification.success({
                        message: `[URL] ${item.url} が正常に削除されました。`,
                    });
                } else {
                    notification.error({
                        message: `[URL] ${item.url} が削除できません。既に店舗がこのドメインを使用中です。`,
                    });
                }
            }
            queryClient.invalidateQueries(ReactQueryKey.GetDomains);
        },
        onError: (error) => {
            handleErrorMessage(error);
        },
    });

    const onFinish = () => {
        mutate(listDelete);
        onClose();
    };

    return (
        <Modal
            title="ドメイン一覧から削除"
            centered
            visible={isModalDeleteShow}
            width={'30vw'}
            footer={null}
            onCancel={() => onClose()}
            maskClosable={false}
            className={styles.wrapper}
        >
            <p>このドメインを削除しますか？</p>
            <Divider />
            <div className={styles.action}>
                <Button onClick={() => onClose()}>いいえ</Button>
                <Button type="primary" onClick={onFinish}>
                    はい
                </Button>
            </div>
        </Modal>
    );
}

export default DeleteDomain;
