import { Table, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { ProgressStatus } from '~/common';

function TableStoreCookie({ stores, isStoresLoading, onTableChange, pagination }) {
    const columns = [
        {
            title: 'サイト',
            render: (text, record, index) => <span>PsCubeサイト</span>,
        },
        {
            title: '店舗コード',
            dataIndex: 'code',
        },
        {
            title: '状態',
            render: (text, record, index) => (
                <>
                    {record.status === ProgressStatus.Waiting && <Tag color="red">クローリング待機 </Tag>}

                    {record.status === ProgressStatus.InProgress && (
                        <Tag color="blue">クローリング中</Tag>
                    )}

                    {record.status === ProgressStatus.Success && <Tag>クローリング済み</Tag>}
                </>
            ),
        },
        {
            title: '日',
            render: (text, record, index) => <>{moment(record.date).format('YYYY/MM/DD')}</>,
        },
    ];

    return (
        <Table
            rowKey={(item) => item.id}
            pagination={pagination}
            loading={isStoresLoading}
            onChange={onTableChange}
            columns={columns}
            dataSource={stores?.data}
        />
    );
}

export default TableStoreCookie;
