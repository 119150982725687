import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import siteApi from '~/api/site.api';
import storeApi from '~/api/store.api';
import { CrawlStatus, PAGE_SIZE } from '~/common';
import { showNotification } from '~/utils';
import StoreTable from './StoreTable';
import AddJob from './AddJob';

const { Option } = Select;

function Recrawl() {
  const [sites, setSites] = useState([]);
  const [currentSite, setCurrentSite] = useState(1);
  const [currentStatus, setCurrentStatus] = useState('');
  const [keyword, setKeyword] = useState('');
  const [stores, setStores] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isModalStoreShow, setModalStoreShow] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: PAGE_SIZE
  });

  async function fetchData() {
    const { pageSize } = pagination;
    const getStoreParams = {
      keyword,
      skip: pageSize * (pagination.current - 1),
      take: pageSize,
      // TODO: Temporary fetch site7 stores only
      siteId: currentSite,
    };

    try {
      const sites = (await siteApi.getAllSites()).data;
      const storeData = (await storeApi.getAllRecrawl(getStoreParams));
      const stores = storeData.data;
      const { totalItems } = storeData;

      setStores(stores);
      setSites(sites);
      setPagination({
        ...pagination,
        total: totalItems
      });
    } catch (error) {
      showNotification('error', error.code, error.message);
    }
    setIsFetching(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [isModalStoreShow]);

  /**
   * Handle table change event
   * @param {*} e 
   */
  const handleTableChange = async (e) => {
    setIsFetching(true);
    const { current, pageSize } = e;
    const getStoreParams = {
      skip: pageSize * (current - 1),
      take: pageSize,
      keyword,
      siteId: currentSite,
      status: currentStatus

    };
    const { data, totalItems } = (await storeApi.getAllRecrawl(getStoreParams));

    setStores(data);
    setPagination({
      ...pagination,
      current,
      total: totalItems,
      pageSize
    });
    setIsFetching(false);
  };

  const handleFormFinish = useCallback(async (value) => {
    setIsFetching(true);
    const { pageSize } = pagination;
    const { keyword, status, siteId } = value
    const getStoreParams = {
      skip: pageSize * (pagination.current - 1),
      take: pageSize,
      ...value
    };
    const { data, totalItems } = (await storeApi.getAllRecrawl(getStoreParams));

    setStores(data);
    setPagination({
      current: 1,
      total: totalItems,
      pageSize: PAGE_SIZE
    });
    setKeyword(keyword);
    setCurrentSite(siteId);
    setCurrentStatus(status);
    setIsFetching(false);
  }, [pagination]);


  return (
    <>
      <h2>補足クローリング</h2>
      <div>
        <Form layout="vertical" autoComplete="off" onFinish={handleFormFinish}>
          <Row gutter={16}>
            <Col span={5}>
              <Form.Item name="keyword" label="店舗名、住所、県" initialValue=''>
                <Input placeholder="店舗名、住所、県で検索できます" autoComplete="none" allowClear />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="siteId" label="サイト">
                <Select placeholder="サイト" defaultValue={1}>
                  {
                    sites.map(site => (
                      <Option
                        key={site.id}
                        value={site.id}>
                        {site.name}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="status" label="状態" initialValue=''>
                <Select placeholder="状態" defaultValue="">
                  <Option value="">全て</Option>
                  {CrawlStatus.map(status => (
                    <Option value={status.value} key={status.value}>{status.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label=" ">
                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
                  検索
                </Button>
              </Form.Item>
            </Col>
            <Col style={{ justifyContent: "flex-end", display: 'flex', flex: 1 }}>
              <Form.Item label=" ">
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  size="middle"
                  onClick={() => setModalStoreShow(true)}
                >
                  リクロル
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div>
        <StoreTable data={stores} pagination={pagination} handleTableChange={handleTableChange} loading={isFetching} />
      </div>
      {isModalStoreShow && <AddJob onClose={() => setModalStoreShow(false)} />}
    </>
  );
}

export default Recrawl;
