import axiosClient from './axiosClient';

const domainApi = {
    getDomainsPscube(params) {
        const url = '/domains';
        return axiosClient.get(url, { params });
    },

    getListDomain(params) {
        const url = '/domains/list-domain';
        return axiosClient.get(url, { params });
    },

    createDomainPscube(params) {
        const url = '/domains';
        return axiosClient.post(url, params);
    },

    deleteDomains(param) {
        const url = '/domains/delete';
        return axiosClient.post(url, param);
    }
};

export default domainApi;
