import { DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import domainApi from '~/api/domain.api';
import siteApi from '~/api/site.api';
import { ActiveStatus, ReactQueryKey } from '~/common';
import AddDomain from '~/components/modals/AddDomain';
import DeleteDomain from '~/components/modals/DeleteDomain';
import TableDomain from '~/components/tables/Domain';
import { handleErrorMessage } from '~/helpers';
import styles from './styles.module.scss';

const { Option } = Select;

function PscubeDomain() {
    const [form] = Form.useForm();
    const [params, setParams] = useState({
        current: 1,
        pageSize: 10,
        total: 1,
        siteId: '',
        keyword: '',
        status: '',
    });

    const [listDelete, setListDelete] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isModalDeleteShow, setModalDeleteShow] = useState(false);

    const { data: sites } = useQuery(ReactQueryKey.GetSites, () => siteApi.getSites());
    const { data: domains, isLoading } = useQuery(
        [ReactQueryKey.GetDomains, params],
        () =>
            domainApi.getDomainsPscube({
                pageIndex: params.current,
                take: params.pageSize,
                siteId: params.siteId,
                keyword: params.keyword,
                status: params.status,
            }),
        {
            onSuccess: (response) => {
                setParams((prev) => ({
                    ...prev,
                    current: response?.pageIndex,
                    total: response?.totalItems,
                }));
            },
            onError: (error) => {
                handleErrorMessage(error);
            },
        }
    );

    const handleTableChange = (pagination) => {
        setParams((prev) => ({
            ...prev,
            current: pagination?.current,
            pageSize: pagination?.pageSize,
        }));
    };

    const onFinish = (values) => {
        setParams((prev) => ({
            ...prev,
            siteId: values?.siteId ? values?.siteId : '',
            keyword: values?.keyword ? values?.keyword : '',
            status:
                values?.status || values?.status === ActiveStatus.InActive ? values?.status : '',
            current: 1,
        }));
    };

    const onStatusChange = (value) => {
        if (value === ActiveStatus.Active) {
            form.setFieldsValue({ status: ActiveStatus.Active });
        } else if (value === ActiveStatus.InActive) {
            form.setFieldsValue({ status: ActiveStatus.InActive });
        } else form.setFieldsValue({ status: '' });
    };

    const handleCloseModal = () => {
        setIsShowModal(false);
    };

    const handleShowModal = () => {
        setIsShowModal(true);
    };

    const handleDelete = () => {
        setModalDeleteShow(true);
    };

    const handleCloseDeleteModal = () => {
        setModalDeleteShow(false);
    };

    return (
        <>
            <h2>ドメイン登録</h2>

            <div className={styles.action}>
                <Button
                    onClick={handleShowModal}
                    icon={<PlusOutlined />}
                    type="primary"
                    size="middle"
                >
                    ドメイン登録
                </Button>
                <Button
                    onClick={handleDelete}
                    disabled={listDelete?.length > 0 ? false : true}
                    danger
                    icon={<DeleteOutlined />}
                    size="middle"
                >
                    ドメイン一覧から削除
                </Button>
            </div>

            <div className={styles.formWrapper}>
                <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="siteId" rules={[]}>
                                <Select placeholder="サイト" onChange={onStatusChange} allowClear>
                                    <Option value="">全て</Option>
                                    {sites?.data?.map((site) => (
                                        <Select.Option key={site.id} value={site.id}>
                                            {site?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="status" rules={[]}>
                                <Select placeholder="状況" onChange={onStatusChange} allowClear>
                                    <Option value="">全て</Option>
                                    <Option value={ActiveStatus.Active}>有効</Option>
                                    <Option value={ActiveStatus.InActive}>無効</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="keyword" rules={[]}>
                                <Input placeholder="ドメイン名、ドメインURL" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item>
                                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
                                    検索
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

            <TableDomain
                domains={domains}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                pagination={params}
                onDelete={(item) => setListDelete(item)}
            />

            <AddDomain sites={sites} isDomainModalShow={isShowModal} onClose={handleCloseModal} />

            {isModalDeleteShow && (
                <DeleteDomain
                    isModalDeleteShow={isModalDeleteShow}
                    listDelete={listDelete}
                    onClose={handleCloseDeleteModal}
                />
            )}
        </>
    );
}

export default PscubeDomain;
