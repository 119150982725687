const { notification } = require("antd");

/**
 * Handle show pop-up notification 
 * @param {*} type 
 * @param {*} message 
 * @param {*} description 
 */
export const showNotification = (type, message, description) => {
  notification[type]({
    message,
    description
  });
};
