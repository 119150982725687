import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, DatePicker, Empty, Modal, Row, Space, Table } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import Loading from '~/components/Loading';
import ModalGraph from '../Graph';
import styles from './styles.module.scss';

const { Panel } = Collapse;

function Line() {
    return (
        <div
            style={{ backgroundColor: '#0000001f', width: '100%', height: '1px', margin: '10px 0' }}
        ></div>
    );
}

function Type({ types, selectedType, onSelectType }) {
    return (
        <div className={styles.typeWrapper}>
            <Space>
                {types?.map((type) => (
                    <div
                        key={type?.id}
                        className={classNames({
                            [styles.type]: true,
                            [styles.typeActive]: type?.id === selectedType?.id,
                        })}
                        onClick={() => onSelectType(type)}
                    >
                        {type?.name}
                    </div>
                ))}
            </Space>
        </div>
    );
}

function Model({
    models,
    selectedModel,
    onSelectModel,
    isModelsLoading,
    units,
    isUnitsLoading,
    onSelectUnit,
    selectedType,
    isGraphsModelShow,
    onGraphModelClose,
    graphs,
    isGraphsLoading,
}) {
    return (
        <div className={styles.modelWrapper}>
            {isModelsLoading && <Loading />}

            {!isModelsLoading && (
                <>
                    {(!models || models?.length === 0) && <Empty />}

                    {models && models?.length > 0 && (
                        <Collapse
                            defaultActiveKey={[]}
                            onChange={(key) => onSelectModel(key)}
                            expandIconPosition={'right'}
                            activeKey={selectedModel?.id}
                        >
                            {models?.map((model) => (
                                <Panel
                                    key={model.id}
                                    header={
                                        <div>
                                            <Row>
                                                <Col span={12}>
                                                    <>{model?.name}</>
                                                </Col>
                                                <Col span={12}>
                                                    <>{`台数 (${model?.machines})`}</>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                >
                                    <Unit
                                        units={units}
                                        isUnitsLoading={isUnitsLoading}
                                        onSelectUnit={onSelectUnit}
                                        selectedType={selectedType}
                                        isGraphsModelShow={isGraphsModelShow}
                                        onGraphModelClose={onGraphModelClose}
                                        graphs={graphs}
                                        isGraphsLoading={isGraphsLoading}
                                    />
                                </Panel>
                            ))}
                        </Collapse>
                    )}
                </>
            )}
        </div>
    );
}

function Unit({
    units,
    isUnitsLoading,
    onSelectUnit,
    selectedType,
    isGraphsModelShow,
    onGraphModelClose,
    graphs,
    isGraphsLoading,
}) {
    let columns;
    if (
        (selectedType?.code && selectedType?.code[0] === '0') ||
        (selectedType?.code && selectedType?.code[0] === 'P')
    ) {
        columns = [
            {
                title: '台番',
                dataIndex: 'unitCode',
            },
            {
                title: '累計スタート',
                dataIndex: 'cumulativeStart',
            },
            {
                title: '大当り回数',
                dataIndex: 'jackpotsTimes',
            },
            {
                title: '初当り回数',
                dataIndex: 'numberFirstHit',
            },
            {
                title: 'アウト',
                dataIndex: 'lastX',
            },
            {
                title: '差玉',
                dataIndex: 'lastY',
            },
            {
                title: 'グラフの２００点',
                render: (text, record, index) => (
                    <Button
                        type="text"
                        style={{ color: '#1976d2' }}
                        onClick={() => onSelectUnit(record)}
                    >
                        詳細
                    </Button>
                ),
            },
        ];
    } else {
        columns = [
            {
                title: '台番',
                dataIndex: 'unitCode',
            },
            {
                title: '総スタート',
                dataIndex: 'totalStart',
            },
            {
                title: 'BB回数',
                dataIndex: 'bbTimes',
            },
            {
                title: 'RB回数',
                dataIndex: 'rbTimes',
            },
            {
                title: 'アウト',
                dataIndex: 'lastX',
            },
            {
                title: '差玉',
                dataIndex: 'lastY',
            },
            {
                title: 'グラフの２００点',
                render: (text, record, index) => (
                    <Button
                        type="text"
                        style={{ color: '#1976d2' }}
                        onClick={() => onSelectUnit(record)}
                    >
                        詳細
                    </Button>
                ),
            },
        ];
    }

    return (
        <>
            <Table
                rowKey={(item) => item.id}
                loading={isUnitsLoading}
                columns={columns}
                dataSource={units}
                pagination={false}
            />

            <ModalGraph
                isGraphsModelShow={isGraphsModelShow}
                onGraphModelClose={onGraphModelClose}
                graphs={graphs}
                isGraphsLoading={isGraphsLoading}
            />
        </>
    );
}

function Store({
    selectedStore,
    models,
    isModelsLoading,
    types,
    selectedType,
    isStoreDetailModelShow,
    onStoreDetailModelClose,
    onSelectType,
    selectedModel,
    onSelectModel,
    units,
    isUnitsLoading,
    onSelectUnit,
    isGraphsModelShow,
    onGraphModelClose,
    graphs,
    isGraphsLoading,
    onDownloadCsv,
}) {
    return (
        <div className={styles.wrapper}>
            <Modal
                title={selectedStore?.name}
                centered
                visible={isStoreDetailModelShow}
                onCancel={() => onStoreDetailModelClose()}
                width={'100vw'}
                footer={null}
                maskClosable={false}
                bodyStyle={{
                    overflowY: 'scroll',
                    height: types?.length !== 0 ? 'calc(100vh - 100px)' : '300px',
                }}
            >
                <div>
                    <p className={styles.csvTitle}>CSVにエクスポート</p>

                    <Space size={'middle'}>
                        <DatePicker defaultValue={moment()} format={'YYYY/MM/DD'} disabled={true} />

                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            disabled={!!(types?.length === 0)}
                            onClick={() => onDownloadCsv()}
                        >
                            CSV
                        </Button>
                    </Space>
                </div>

                <Line />

                <div>
                    <Type types={types} selectedType={selectedType} onSelectType={onSelectType} />
                </div>

                <div>
                    <Model
                        models={models}
                        isModelsLoading={isModelsLoading}
                        selectedModel={selectedModel}
                        onSelectModel={onSelectModel}
                        units={units}
                        isUnitsLoading={isUnitsLoading}
                        onSelectUnit={onSelectUnit}
                        selectedType={selectedType}
                        isGraphsModelShow={isGraphsModelShow}
                        onGraphModelClose={onGraphModelClose}
                        graphs={graphs}
                        isGraphsLoading={isGraphsLoading}
                    />
                </div>
            </Modal>
        </div>
    );
}

export default Store;
