import { Button, Divider, Modal, notification } from 'antd';
import { useQueryClient, useMutation } from 'react-query';
import storeApi from '~/api/store.api';
import { ReactQueryKey } from '~/common';
import { handleErrorMessage } from '~/helpers';
import styles from './styles.module.scss';

function Priority({ isPriorityModalShow, onClose, isPriority, onSelectedRowsKey }) {
    const queryClient = useQueryClient();
    const addPriority = useMutation((params) => storeApi.setPriorityPscubeRecaptcha(params), {
        onSuccess: () => {
            notification.success({
                message: '動作成功',
                description: '優先度一覧に追加しました。',
            });
            queryClient.invalidateQueries(ReactQueryKey.GetStoreRecaptcha);
        },
        onError: (error) => {
            handleErrorMessage(error);
        },
    });

    const deletePriority = useMutation((params) => storeApi.setPriorityPscubeRecaptcha(params), {
        onSuccess: () => {
            notification.success({
                message: '動作成功',
                description: '優先度一覧から削除しました。',
            });
            queryClient.invalidateQueries(ReactQueryKey.GetStoreRecaptcha);
        },
        onError: (error) => {
            handleErrorMessage(error);
        },
    });

    const onFinish = () => {
        if (isPriority?.priority === 1) {
            addPriority.mutate(isPriority);
        } else deletePriority.mutate(isPriority);
        onClose();
        onSelectedRowsKey();
    };

    return (
        <Modal
            title="優先度設定"
            centered
            visible={isPriorityModalShow}
            width={'30vw'}
            footer={null}
            onCancel={() => onClose()}
            maskClosable={false}
            className={styles.wrapper}
        >
            {isPriority?.priority === 1 && <p>選択した店舗を優先度一覧に追加しますか。</p>}
            {isPriority?.priority === 0 && <p>選択した店舗を優先度一覧から削除しますか。</p>}
            <Divider />
            <div className={styles.action}>
                <Button onClick={() => onClose()}>キャンセル</Button>
                <Button type="primary" onClick={onFinish}>
                    はい
                </Button>
            </div>
        </Modal>
    );
}

export default Priority;
