import axios from 'axios';
import Cookies from 'js-cookie';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosClient.interceptors.request.use(
    function (config) {
        config.headers.authorization = `Bearer ${Cookies.get('token')}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    function (response) {
        if (response?.request?.responseURL?.indexOf('/download/csv') !== -1) {
            return response;
        }
        return response.data;
    },
    function (error) {
        if (error.response.status === 401) {
            Cookies.remove('token');
        }

        return Promise.reject(error);
    }
);

export default axiosClient;
