import axiosClient from './axiosClient';

const graphApi = {
    getGraphs(params) {
        const url = '/graphs';
        return axiosClient.get(url, { params });
    },
};

export default graphApi;
