import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { notification, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ProgressStatus } from '~/common';

function TagReCrawlText({ subtract }) {
    return (
        <>
            {subtract !== 0 ? (
                <>{moment().subtract(subtract, 'days').format('YYYY/MM/DD')}日のデータ</>
            ) : (
                <>当日のデータ</>
            )}
        </>
    );
}

function TagReCrawl({ record, subtract, onReCrawl }) {
    const [item, setItem] = useState(null);

    useEffect(() => {
        let result;
        if (!subtract) {
            result = record?.sitePsCubeCookies?.find(
                (item) => Number(item?.subtract) === Number(subtract) && Number(item?.type) === 1
            );
        } else {
            result = record?.sitePsCubeCookies?.find(
                (item) =>
                    Number(item?.subtract) === Number(subtract) ||
                    moment().diff(moment(item?.date), 'days') === subtract - 1
            );
        }

        setItem(result);
    }, [record, subtract]);

    const handleReCrawl = () => {
        if (!record?.cookies) {
            notification.error({
                message: '動作失敗',
                description: 'Cookies取得動作がまだ実行されていないため、クローリング不可です。',
            });
        } else {
            onReCrawl({ code: record?.code, cookies: record?.cookies, type: 1, subtract });
        }
    };

    const dateWithSubtract = moment().subtract(subtract, 'days').format('YYYY-MM-DD');
    const hasData = record?.days.includes(dateWithSubtract);

    return (
        <div style={{ cursor: 'pointer' }}>
            {(!item && !hasData) && (
                <Tag color="red" icon={<CloseCircleOutlined />} onClick={handleReCrawl}>
                    <TagReCrawlText subtract={subtract} />
                </Tag>
            )}

            {item && item?.status === ProgressStatus.Waiting && (
                <Tag color="gold" icon={<ClockCircleOutlined />} onClick={handleReCrawl}>
                    <TagReCrawlText subtract={subtract} />
                </Tag>
            )}

            {item && item?.status === ProgressStatus.InProgress && (
                <Tag color="processing" icon={<SyncOutlined spin />} onClick={handleReCrawl}>
                    <TagReCrawlText subtract={subtract} />
                </Tag>
            )}

            {((item && item?.status === ProgressStatus.Success) || (!item && hasData)) && (
                <Tag color="success" icon={<CheckCircleOutlined />} onClick={handleReCrawl}>
                    <TagReCrawlText subtract={subtract} />
                </Tag>
            )}
        </div>
    );
}

export default TagReCrawl;
