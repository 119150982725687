import axiosClient from './axiosClient';

const downloadApi = {
    csv(params) {
        const url = '/download/csv';
        return axiosClient.post(url, params);
    },
};

export default downloadApi;
