import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import React from 'react';
import Sidenav from './SideNav';
import styles from './styles.module.scss';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

function DefaultLayout({ children }) {
    const navigate = useNavigate();

    const handleLogout = () => {
        Cookies.remove('token');
        Cookies.remove('role');
        navigate('/login');
    };
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: <div onClick={handleLogout}>ログアウト</div>,
                    icon: <LogoutOutlined />,
                },
            ]}
        />
    );
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sidenav />

            <Layout>
                <Header className={styles.header}>
                    <div></div>
                    <div className={styles.userWrapper}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <div>
                                <Avatar icon={<UserOutlined />} />
                            </div>
                        </Dropdown>
                    </div>
                </Header>
                <div className={styles.content}>{children}</div>
            </Layout>
        </Layout>
    );
}

export default DefaultLayout;
