import { ConfigProvider } from 'antd';
import 'antd/dist/antd.min.css';
import jp from 'antd/lib/locale/ja_JP';
import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import '~/styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();
moment.tz.setDefault('Asia/Tokyo');
moment.locale('ja');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ConfigProvider locale={jp}>
                <App />
            </ConfigProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
