import { DatePicker } from "antd";
import moment from "moment";

const DATE_DURATION = {
  0: -1,
  1: 7, // Duration for site7: 7 days
  2: -1, // Duration for papimo: no limit
  3: -1,
  4: -1,
  7: -1,
  17: 7,
}

/**
 * Disable non-selectable dates
 * @param {*} current 
 * @returns 
 */
function disabledDate(current, duration) {
  
  // Allow to choose between 3-7 days ago from the current date
  const weekStart = moment().subtract(duration, 'day');
  const weekEnd = moment().subtract(1, 'day');

  // If duration equals -1, remove limit for the days before current date
  if (duration === -1 ) {
    return !weekEnd.isAfter(current)
  }

  return !(weekStart.isSameOrBefore(current) && weekEnd.isAfter(current));
}

function DatePickerCell({ status, onChange, siteId }) {
  return (
    <DatePicker 
      status={status}
      disabledDate={(date) => disabledDate(date, DATE_DURATION[siteId])}
      onChange={onChange}
    />
  );
}

export default DatePickerCell;
