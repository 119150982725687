import { Table, Tag } from 'antd';
import React, { useState } from 'react';
import { ActiveStatus } from '~/common';

function TableDomain({ domains, isLoading, onTableChange, pagination, onDelete }) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const columns = [
        {
            title: 'サイト名',
            render: (text, record, index) => <span>{record?.site}</span>,
            width: '10%',
        },
        {
            title: 'ドメイン名',
            render: (text, record, index) => <span>{record?.name}</span>,
            width: '40%',
        },
        {
            title: '店舗コードドメインURL',
            render: (text, record, index) => <span>{record?.url}</span>,
            width: '40%',
        },
        {
            title: '状況',
            render: (text, record, index) => (
                <>
                    {record.status === ActiveStatus.Active && <Tag color="success">有効</Tag>}
                    {record.status === ActiveStatus.InActive && <Tag color="red">無効</Tag>}
                </>
            ),
            width: '10%',
        },
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        onDelete(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Table
            rowKey={(item) => item.id}
            pagination={pagination}
            loading={isLoading}
            onChange={onTableChange}
            columns={columns}
            rowSelection={rowSelection}
            dataSource={domains?.data}
        />
    );
}

export default TableDomain;
