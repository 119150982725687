import { Button, Divider, Form, Input, Modal, notification, Select } from 'antd';
import { useQueryClient, useMutation } from 'react-query';
import domainApi from '~/api/domain.api';
import { ReactQueryKey } from '~/common';
import { handleErrorMessage } from '~/helpers';
import styles from './styles.module.scss';

function AddDomain({ isDomainModalShow, onClose, sites }) {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();

    const { mutate } = useMutation((params) => domainApi.createDomainPscube(params), {
        onSuccess: () => {
            notification.success({
                message: '[URL] が正常に追加されました。',
            });
            queryClient.invalidateQueries(ReactQueryKey.GetDomains);
            form.resetFields();
        },
        onError: (error) => {
            handleErrorMessage(error);
        },
    });

    const onFinish = (value) => {
        value = {
            ...value,
            name: value?.name?.trim(),
            url: value?.url?.trim(),
        };
        mutate(value);
        onClose();
    };

    const handleClickOuteSite = () => {
        form.setFieldsValue({
            name: form.getFieldValue('name')?.trim(),
            url: form.getFieldValue('url')?.trim(),
        });
    };

    return (
        <div onClick={handleClickOuteSite}>
            <Modal
                title="ドメイン登録"
                centered
                visible={isDomainModalShow}
                width={'25vw'}
                footer={null}
                onCancel={() => onClose(form.resetFields())}
                maskClosable={false}
                className={styles.wrapper}
            >
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="siteId"
                        label="サイト名"
                        rules={[{ required: true, message: 'サイト名は必須です。' }]}
                    >
                        <Select>
                            {sites?.data?.map((site) => (
                                <Select.Option key={site.id} value={site.id}>
                                    {site.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="ドメイン登録"
                        name="name"
                        rules={[
                            { required: true, message: 'ドメイン名は必須です。' },
                            ({ getFieldValue }) => ({
                                validator() {
                                    if (getFieldValue('name')?.trim().length > 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject({ message: '' });
                                },
                            }),
                            {
                                pattern: /^.{1,255}$/,
                                message: 'ドメイン名は255文字以下で入力してください。',
                            },
                        ]}
                    >
                        <Input maxLength={256} />
                    </Form.Item>
                    <Form.Item
                        label="ドメインURL"
                        name="url"
                        rules={[
                            { required: true, message: 'ドメインURLは必須です。' },
                            {
                                pattern:
                                    /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/, // eslint-disable-line
                                message: '[URL] が正しくありません。',
                            },
                            {
                                pattern: /^.{1,255}$/,
                                message: 'ドメインURLは255文字以下で入力してください。',
                            },
                        ]}
                    >
                        {<Input maxLength={256} />}
                    </Form.Item>
                    <Divider />
                    <Form.Item>
                        <div className={styles.action}>
                            <Button onClick={() => onClose(form.resetFields())}>キャンセル</Button>
                            <Button type="primary" htmlType="submit">
                                登録
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default AddDomain;
