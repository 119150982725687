import PscubeRecaptcha from '~/pages/PscubeRecaptcha';
import Login from '~/pages/Login';
import PscubeCookies from '~/pages/PscubeCookies';
import PscubeDomains from '~/pages/PscubeDomains';
import { Recrawl } from '~/pages/Recrawl';

const publicRoutes = [{ path: '/login', component: Login, layout: null }];

const privateRoutes = [
    { path: '/', component: PscubeRecaptcha },
    { path: '/logs', component: PscubeCookies },
    { path: '/domain', component: PscubeDomains },
    { path: '/recrawl', component: Recrawl }
];

export { publicRoutes, privateRoutes };
