import { ShopOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoImg from '~/assets/images/logodasboard.5890cd0.png';
import styles from '~/layouts/DefaultLayout/styles.module.scss';

const { Sider } = Layout;

const routes = [
    {
        key: '1',
        label: '店舗管理',
        url: '/',
        icon: <ShopOutlined />,
    },
    {
        key: '2',
        label: 'Recaptchaデータ取得ログ',
        url: '/logs',
        icon: <ShopOutlined />,
    },
    {
        key: '3',
        label: 'ドメイン登録',
        url: '/domain',
        icon: <ShopOutlined />,
    },
    {
        key: '4',
        label: 'リクロル',
        url: '/recrawl',
        icon: <ShopOutlined />,
    },
];

function Sidenav() {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKey, setSelectedKey] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useLayoutEffect(() => {
        const selected = routes.find((r) => r.url === location.pathname);
        setSelectedKey(selected?.key || '/');
        navigate(location.pathname);
    }, [location.pathname, navigate]);

    const handleClick = (e) => {
        setSelectedKey(e.key);
        const selected = routes.find((r) => r.key === e.key);
        navigate(selected?.url || '/');
    };

    return (
        <Sider
            width={260}
            theme="light"
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
        >
            <div className={styles.logo} onClick={() => navigate('/')} >
                <img src={LogoImg} alt="logo"/>
                {!collapsed && <span>パチンコ</span>}
            </div>
            <Menu
                selectedKeys={[selectedKey]}
                theme="light"
                defaultOpenKeys={[]}
                mode="inline"
                items={routes}
                onClick={handleClick}
            ></Menu>
        </Sider>
    );
}

export default Sidenav;
