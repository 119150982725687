import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import storeApi from '~/api/store.api';
import { ProgressStatus, ReactQueryKey } from '~/common';
import TableStoreCookie from '~/components/tables/StoreCookie';
import { handleErrorMessage } from '~/helpers';
import styles from './styles.module.scss';

const { Option } = Select;

function PscubeCookies() {
    const [form] = Form.useForm();
    const [params, setParams] = useState({
        current: 1,
        pageSize: 10,
        total: 1,
        keyword: '',
        status: '',
    });

    const { data: stores, isLoading: isStoresLoading } = useQuery(
        [ReactQueryKey.GetStoreCookies, params],
        () =>
            storeApi.getStoresCookies({
                pageIndex: params.current,
                take: params.pageSize,
                keyword: params.keyword,
                status: params.status,
            }),
        {
            onSuccess: (response) => {
                setParams((prev) => ({
                    ...prev,
                    current: response?.pageIndex,
                    total: response?.totalItems,
                }));
            },
            onError: (error) => {
                handleErrorMessage(error);
            },
        }
    );

    const onFinish = (values) => {
        setParams((prev) => ({
            ...prev,
            keyword: values?.keyword ? values?.keyword : '',
            status:
                values?.status || values?.status === ProgressStatus.Waiting
                    ? values?.status
                    : '',
            current: 1,
        }));
    };

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setParams((prev) => ({
            ...prev,
            current: pagination?.current,
            pageSize: pagination?.pageSize,
        }));
    };

    const onGenderChange = (value) => {
        switch (value) {
            case '':
                form.setFieldsValue({ status: '' });
                return;
            case ProgressStatus.InProgress:
                form.setFieldsValue({ status: ProgressStatus.InProgress });
                return;
            case ProgressStatus.Waiting:
                form.setFieldsValue({ status: ProgressStatus.Waiting });
                return;

            case ProgressStatus.Success:
                form.setFieldsValue({ status: ProgressStatus.Success });
                return;

            default:
                return;
        }
    };

    return (
        <>
            <h2>店舗管理</h2>

            <div className={styles.formWrapper}>
                <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="keyword" rules={[]}>
                                <Input placeholder="店舗名、住所、県で検索できます" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="status" rules={[]}>
                                <Select placeholder="状態" onChange={onGenderChange} allowClear>
                                    <Option value="">全て</Option>
                                    <Option value={ProgressStatus.Waiting}>
                                        クローリング待機
                                    </Option>
                                    <Option value={ProgressStatus.InProgress}>
                                        クローリング中
                                    </Option>
                                    <Option value={ProgressStatus.Success}>
                                        クローリング済み
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item>
                                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
                                    検索
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

            <TableStoreCookie
                stores={stores}
                isStoresLoading={isStoresLoading}
                onTableChange={handleTableChange}
                pagination={params}
            />
        </>
    );
}

export default PscubeCookies;
