import { Button, Form, Input } from 'antd';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authApi from '~/api/auth';
import LogoImg from '~/assets/images/logopachinko.a355b9e.jpeg';
import styles from './styles.module.scss';
import { handleErrorMessage } from '~/helpers';

function Login() {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish = async (values) => {
        try {
            const { data } = await authApi.login(values);
            Cookies.set('token', data?.token);
            Cookies.set('role', data?.role);
            navigate('/');
        } catch (error) {
            handleErrorMessage(error);
        }
    };

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) navigate('/');
        // eslint-disable-next-line
    }, []);

    return (
        <div className={styles.loginWrapper}>
            <div className={styles.topBgr}></div>

            <div className={styles.loginForm}>
                <div className={styles.logo}>
                    <img src={LogoImg} alt="logo" />
                </div>
                <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                    <Form.Item
                        label="ユーザー名"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'ユーザー名を入力してください。!',
                            },
                        ]}
                    >
                        <Input
                        // placeholder="ユーザー名"
                        />
                    </Form.Item>

                    <Form.Item
                        label="パスワード"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'パスワードを入力して下さい。!',
                            },
                        ]}
                    >
                        <Input.Password
                        //  placeholder="パスワード"
                        />
                    </Form.Item>

                    <Form.Item
                        style={{
                            textAlign: 'end',
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            ログイン
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default Login;
