import axiosClient from './axiosClient';

const cookieApi = {
    createReCrawl(params) {
        const url = '/cookies';
        return axiosClient.post(url, params);
    },
};

export default cookieApi;
