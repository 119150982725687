import { Button, Divider, Form, Input, Modal, notification, Select } from 'antd';
import { useState } from 'react';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import domainApi from '~/api/domain.api';
import prefectureApi from '~/api/prefecture.api';
import storeApi from '~/api/store.api';
import { ReactQueryKey, SiteId, StoreStatus } from '~/common';
import { handleErrorMessage } from '~/helpers';
import styles from './styles.module.scss';

function AddStore({ sites, isModalStoreShow, onClose }) {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const [listDomain, setListDomain] = useState([]);
    const [isSelect, setSelect] = useState(true);

    const { data: prefectures } = useQuery(ReactQueryKey.GetPrefecture, () =>
        prefectureApi.getPrefecture()
    );

    const { data: domains } = useQuery(ReactQueryKey.GetListDomain, () =>
        domainApi.getListDomain()
    );

    const { mutate } = useMutation((params) => storeApi.createStoresPscubeRecaptcha(params), {
        onSuccess: () => {
            notification.success({
                message: '店舗が追加されました',
            });
            queryClient.invalidateQueries(ReactQueryKey.GetStoreRecaptcha);
            form.resetFields();
        },
        onError: (error) => {
            handleErrorMessage(error);
        },
    });

    const onFinish = async (value) => {
        value.siteId === SiteId.PsCube
            ? (value.status = StoreStatus.Recaptcha)
            : (value.status = StoreStatus.Normal);
        value = {
            ...value,
            name: value?.name?.trim(),
            code: value?.code?.trim(),
            address: value?.address?.trim(),
            domainId: value?.domainId === '' ? null : value?.domainId,
        };
        mutate(value);
        onClose();
    };

    const handleFieldChange = (changedFields) => {
        if (changedFields[0].name[0] === 'siteId') {
            form.setFieldsValue({ domainId: '' });
        }
        const siteId = form.getFieldValue('siteId');
        if (siteId) {
            if (siteId === SiteId.Teramoba2) {
                setListDomain(domains?.data.filter((item) => item.site.includes('Teramoba2')));
            } else setListDomain(domains?.data.filter((item) => item.siteId === siteId));
            setSelect(false);
        }
    };

    const handleClickOuteSite = () => {
        form.setFieldsValue({
            name: form.getFieldValue('name')?.trim(),
            code: form.getFieldValue('code')?.trim(),
            address: form.getFieldValue('address')?.trim(),
        });
    };

    return (
        <div onClick={handleClickOuteSite}>
            <Modal
                title="店舗追加"
                centered
                visible={isModalStoreShow}
                width={'40vw'}
                footer={null}
                onCancel={() => {
                    setSelect(true);
                    onClose(form.resetFields());
                }}
                maskClosable={false}
                className={styles.wrapper}
            >
                <Form
                    onFieldsChange={handleFieldChange}
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="siteId"
                        label="サイト名"
                        rules={[{ required: true, message: 'サイト名は必須です。' }]}
                    >
                        <Select>
                            {sites?.data?.map((site) => (
                                <Select.Option key={site.id} value={site.id}>
                                    {site.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="店舗名"
                        name="name"
                        rules={[
                            { required: true, message: '店舗名は必須です。' },
                            ({ getFieldValue }) => ({
                                validator() {
                                    if (getFieldValue('name')?.trim().length > 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject({ message: '' });
                                },
                            }),
                            {
                                pattern: /^.{1,255}$/,
                                message: '店舗名は255文字以下で入力してください。',
                            },
                        ]}
                    >
                        <Input maxLength={256} />
                    </Form.Item>
                    <Form.Item
                        label="店舗コード"
                        name="code"
                        rules={[
                            { required: true, message: '店舗コードは必須です。' },
                            ({ getFieldValue }) => ({
                                validator() {
                                    if (getFieldValue('code')?.trim().length > 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject({ message: '' });
                                },
                            }),
                            {
                                pattern: /^.{1,50}$/,
                                message: '店舗コードは50文字内で入力してください',
                            },
                        ]}
                    >
                        <Input maxLength={51} />
                    </Form.Item>
                    <Form.Item
                        name="prefectureId"
                        label="県"
                        rules={[{ required: true, message: '県は必須です。' }]}
                    >
                        <Select>
                            {prefectures?.data?.map((prefecture) => (
                                <Select.Option key={prefecture.id} value={prefecture.id}>
                                    {prefecture.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="住所"
                        name="address"
                        rules={[
                            { required: true, message: '住所は必須です。' },
                            ({ getFieldValue }) => ({
                                validator() {
                                    if (getFieldValue('address')?.trim().length > 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject({ message: '' });
                                },
                            }),
                            {
                                pattern: /^.{1,1000}$/,
                                message: '住所は1000文字以下で入力してください。',
                            },
                        ]}
                    >
                        <Input maxLength={1001} />
                    </Form.Item>
                    <Form.Item
                        name="domainId"
                        label="ドメイン選択"
                        rules={[{ required: true, message: 'ドメイン選択は必須です。' }]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={isSelect}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {listDomain?.map((domain) => (
                                <Select.Option key={domain.id} value={domain.id}>
                                    {domain.url}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Divider />
                    <Form.Item>
                        <div className={styles.action}>
                            <Button
                                onClick={() => {
                                    setSelect(true);
                                    onClose(form.resetFields());
                                }}
                            >
                                キャンセル
                            </Button>
                            <Button type="primary" htmlType="submit">
                                追加
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default AddStore;
